<template>
    <div>
      <Footer ></Footer>
        <iframe src="/static/oss_upload/index.html" ref="iframe" width="100%" height="900px" scrolling="no"></iframe>
       <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import $ from "jquery";
  
  export default {
    name: 'Home',
    components: {
      Footer,
      Bottom
    },
    data(){
      return {
      }
    },
    methods:{
  
    },
  }
  </script>
  
  <style scoped lang="scss">
  @import './upload.scss'
  </style>